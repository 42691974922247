import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'
import Accordion from '../components/accordion'

export default (props) => {
  const {
    pageTitle,
    subtitle,
    openings,
    hrEmail,
    subtitleNoJobs,
  } = props.data.allContentfulPageCareers.nodes[0]

  const accordionData = openings.map((opening) => ({
    title: opening.role,
    content: opening.description.description,
  }))

  let noJobs = false
  if (accordionData.length === 1 && accordionData[0].title.toLowerCase() === 'none') {
    noJobs = true
  }

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-9 mb-4 pbb-15">
          {noJobs ? (
            <PageSubtitle subtitle={subtitleNoJobs} />
          ) : (
            <>
              <PageSubtitle subtitle={subtitle} />
              <div className="row">
                <div className="col-md-8">
                  <Accordion accords={accordionData} />
                </div>
                <div className="col-md-4">
                  <div className="background-grey-2 rounded p-3">
                    <h3 className="text-center">Applying to job?</h3>
                    <p className="text-grey-6 mt-4">
                      If you would like to apply for a listed job opportunity or have questions
                      related to careers at KVE.
                      <br />
                      Please write to us at{' '}
                      <a href={`mailto:${hrEmail}`} className="text-black">
                        {hrEmail}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query careersQuery {
    allContentfulPageCareers {
      nodes {
        pageTitle
        subtitle
        subtitleNoJobs
        openings {
          role
          description {
            description
          }
        }
        hrEmail
      }
    }
  }
`
