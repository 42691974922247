import React from 'react'

export default ({ accords }) => (
  <>
    <ul className="accordion pl-0 mb-0 border border-right-0 border-left-0 border-bottom-0">
      {accords.map((accord) => (
        <li key={accord.title}>
          <input type="checkbox" />
          <h6 className="accordion-heading my-1 py-3">
            <i className="fas fa-chevron-circle-down mr-4 text-purple font-weight-bold"></i>
            {accord.title}
          </h6>
          <div className="accordion-body border border-right-0 border-left-0 border-top-0">
            {accord.content}
            <div className="pb-4"></div>
          </div>
        </li>
      ))}
    </ul>
  </>
)
